//变量
@c-333: #333;
@c-666: #666;
@c-999: #999;
@c-fff: #ffffff;
@c-2ff: #3072FF;
@c-202: #D00202;

.pay {
  background: #F6F6F6;
}
.btnBox {
  padding: 10vw 0;
}
.nextBtn {
  display: block;
  width: 95vw;
  margin: 0 auto;
  color: #4C3527;
  background: #ffc107;
  padding: 4vw 0;
  border-radius: 50px;
}
.selfBox {
  width: 95vw;
  margin: 0 auto;
  border-radius: 3vw;
  border: 1px solid #eee;
  padding: 3vw;
}
.my {
  background: dodgerblue;
  width: 13vw;
  text-align: center;
  padding: 1vw 0;
  border-radius: 6px;
}
.c-blue {
  color: dodgerblue;
}
.rightFlex {
  display: flex;
  align-items: center;
}
.right {
  position: relative;
  top: 0;
}
.fontIcon {
  position: absolute;
  right: 0;
  top: 3.2vw;
}
.self {
  padding: 4vw 0;
  background: #ffc107;
  border-radius: 3vw;
  width: 25vw;
}
.box {
  padding: 3vw;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box:first-child {
  border-top: none;
}
/deep/ .van-field__control {
  text-align: right;
  padding-right: 2.5vw;
}

@blue: @c-2ff;